<template>
  <div class="home pb-12 mb-12">
    <h2 class="accent--text">
      <v-icon color="primary" class="ml-3" large>mdi-account-tie</v-icon>
     مرحبا
      <back-button />
    </h2>
    <hr class="my-5 header-divider" />

    <numeric-statistics class="mb-10" ></numeric-statistics>
   
    <br />
    <!-- <contact-us-alert /> -->
  </div>
</template>


<script>
// import ContactUsAlert from './ContactUsAlert';
import NumericStatistics from './NumericStatistics';

export default {
  name: 'home',
  components: { NumericStatistics },
  
  data: function () {
    return {
      // ratio: {},
      // cityShops: [],
      // loading: true
    }
  },
  
  created() {
  },


  methods: {


  }
}



</script>
