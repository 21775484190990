<template>
  <v-row>
    <v-col cols="12" sm="6">
      <router-link to="/users">
        <div
          class="
            px-3
            py-10
            text-center
            white--text
            blue-grey
            darken-2
            rounded
            numericStatistics
          "
        >
          <br />
          <span class="d-block title mb-2">المستخدمين</span>
          <br />
          <!-- <v-progress-circular v-if="loading" indeterminate color="white" /> -->
          <!-- <strong v-else class="title">{{ statistics.users }}</strong> -->
        </div>
      </router-link>
    </v-col>

    <v-col cols="12" sm="6">
      <router-link to="/ads">
        <div class="px-3 py-10 text-center white--text teal darken-2 rounded">
          <br />
          <span class="d-block title mb-2">الاعلانات</span>
          <br />
          <!-- <v-progress-circular v-if="loading" indeterminate color="white" /> -->
          <!-- <strong v-else class="title">{{ statistics.order }}</strong> -->
        </div>
      </router-link>
    </v-col>

    <v-col cols="12" sm="4">
      <router-link to="/admin/pages/privacy">
        <div class="px-3 py-10 text-center white--text green darken-3 rounded">
          <span class="d-block title mb-2">الشروط و الاحكام</span>
          <!-- <v-progress-circular v-if="loading" indeterminate color="white" /> -->
          <!-- <strong v-else class="title">{{ statistics.products }}</strong> -->
        </div>
      </router-link>
    </v-col>

    <v-col cols="12" sm="4">
      <router-link to="/admins">
        <div class="px-3 py-10 text-center white--text deep-orange rounded">
          <span class="d-block title mb-2">المدراء</span>
          <!-- <v-progress-circular v-if="loading" indeterminate color="white" /> -->
          <!-- <strong v-else class="title">{{ statistics.categories }}</strong> -->
        </div>
      </router-link>
    </v-col>

    <v-col cols="12" sm="4">
      <router-link to="/wallet_balances">
        <div
          class="px-3 py-10 text-center white--text light-blue darken-2 rounded"
        >
          <span class="d-block title mb-2"> المحفظة والأرصدة</span>
          <!-- <v-progress-circular v-if="loading" indeterminate color="white" /> -->
          <!-- <strong v-else class="title">{{ statistics.subcategories }}</strong> -->
        </div>
      </router-link>
    </v-col>
  </v-row>
</template>


<script>
export default {
  name: "numeric-statistics",

  // data: function () {
  //   return {
  //     statistics: {},
  //     loading: true
  //   }
  // },

  // created() {
  //   this.fetchNumericStatistics();
  // },

  // methods: {
  //   fetchNumericStatistics() {
  //     this.loading = true;

  //     this.axios.get('/admin/count')
  //       .then((response) => {
  //         this.statistics = response.data;
  //       })
  //       .catch((error) => {
  //         // console.log(error, error.response);
  //         this.$bus.$emit('showSnackbar', {
  //           text: 'خطأ أثناء جلب الإحصائيات الرقمية',
  //           color: 'accent'
  //         });
  //       })
  //       .finally(() => {
  //         this.loading = false;
  //       });
  //   }
  // }
  // }}
};
</script>


<style scoped>
.col-12:hover {
  background-color: rgba(107, 79, 23, 0.322);
  border-radius: 20px;
  height: max-content;
  transition: 0.5s ease;
}
</style>